import { CommonModule } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { DateTime } from 'luxon';
import { openManageSubscriptionDialog } from '../subscriptions/manage-subscription-dialog/manage-subscription-dialog.component';
import { SubscriptionModel } from '../../../../models/user.model';
import { UserStoreService } from '../../../../services/stores/user-store.service';
import { SubscriptionService } from '../../../../services/subscriptions/subscription.service';
import { ConfirmationDialogComponent } from '../../../../shared/confirmation-dialog/confirmation-dialog.component';
import { environment } from '../../../../../environments/environment';

@Component({
    selector: 'app-billing-info',
    templateUrl: 'billing-info.component.html',
    styleUrls: ['billing-info.component.scss'],
    imports: [
        CommonModule,
        FormsModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatOptionModule,
        ReactiveFormsModule,
        RouterModule,
    ]
})
export class BillingInfoComponent implements OnInit {
	constructor(
		private subscriptionService: SubscriptionService,
		private userStoreService: UserStoreService,
		public dialog: MatDialog,
	) {}

	subscription = {} as SubscriptionModel;
	hasActiveSubscription$ = signal(false);
	subscriptionWillExpire$ = signal(false);
	subscriptionHasExpired$ = signal(false);
	hasActiveTrialSubscription$ = signal(false);
	nextBillingCycleDate: string | undefined = undefined;
	billingPeriodUnit: string = 'Monthly';
	isCanceling$ = signal(false);
	cancelMessage$ = signal('');
	trialEndDate: string = '';
	isTrial: boolean = false;
	loading$ = signal(true);
	marketingSiteUrl = environment.marketingSiteUrl;

	async ngOnInit(): Promise<void> {
		this.loading$.set(true);
		this.subscription =
			this.userStoreService.userData$()?.getUserData()?.getSubscriptionModel() ?? ({} as SubscriptionModel);

		this.hasActiveSubscription$.set(this.subscription?.getHasActiveSubscription() === true);

		let trialEndDate = this.subscription?.getTrialEndDateUtc() ?? '2000-01-01T00:00:00Z';
		if (new Date(trialEndDate) > new Date()) {
			this.hasActiveTrialSubscription$.set(true);
		}
		if (this.subscription?.getTrialEndDateUtc()) {
			this.trialEndDate = DateTime.fromISO(this.subscription?.getTrialEndDateUtc()!).toLocaleString();
		}

		if (this.subscription && this.subscription.getNextBillingCycleDateUtc()) {
			this.nextBillingCycleDate = DateTime.fromISO(this.subscription.getNextBillingCycleDateUtc()!).toLocaleString();
		}

		if (this.subscription && this.subscription.getBillingPeriodUnit()) {
			switch (this.subscription.getBillingPeriodUnit().toLowerCase()) {
				case 'year':
					this.billingPeriodUnit = 'Yearly';
					break;
			}
		}

		if (this.subscription && this.subscription.getHasCancelledSubscription()) {
			if (new Date() > new Date(this.subscription.getNextBillingCycleDateUtc()!)) {
				this.subscriptionHasExpired$.set(true);
			} else {
				this.subscriptionWillExpire$.set(true);
			}
		}

		if (this.subscription && this.subscription.getTrialEndDateUtc() && !this.subscription?.getHasActiveSubscription()) {
			this.isTrial = false;
			this.trialEndDate = DateTime.fromISO(this.subscription.getTrialEndDateUtc()!).toLocaleString();
		}
		this.loading$.set(false);
	}

	showManageSubscriptionDialog(billingPeriodUnit: 'Monthly' | 'Yearly' = 'Monthly'): void {
		openManageSubscriptionDialog(this.dialog, billingPeriodUnit.toLowerCase() as 'monthly' | 'yearly');
	}

	getSubscriptionStatus(): string {
		if (!this.subscription) {
			return 'No Active Subscription';
		}

		switch (this.subscription.getSubscriptionStatus().toLowerCase()) {
			case 'active':
				return 'Active';
			case 'cancelled':
				return 'Cancelled';
			case 'intrial':
				return 'Trial';
		}

		return this.subscription.getSubscriptionStatus();
	}

	async cancelSubscription(): Promise<void> {
		this.isCanceling$.set(true);
		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			action: 'cancel',
			theSubject: `your subscription`,
			additionalInformation: `You will have access to your features until ${this.nextBillingCycleDate}.`,
			hideUltimatum: true,
		};

		const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
		dialogRef.afterClosed().subscribe(async (result) => {
			if (result === 'yes') {
				await this.subscriptionService.cancelSubscription();
				this.cancelMessage$.set('Your subscription has been cancelled.');
				await this.userStoreService.reinitialize();
				this.subscription =
					this.userStoreService.userData$()?.getUserData()?.getSubscriptionModel() ?? ({} as SubscriptionModel);
				this.hasActiveSubscription$.set(false);
				this.subscriptionWillExpire$.set(true);
			}
			this.isCanceling$.set(false);
		});
	}

	async reactivateSubscription(): Promise<void> {
		const newSubscriptionInfo = await this.subscriptionService.reactivateSubscription();
		await this.userStoreService.reinitialize();
		this.subscription =
			this.userStoreService.userData$()?.getUserData()?.getSubscriptionModel() ?? ({} as SubscriptionModel);
		this.hasActiveSubscription$.set(this.subscription.getHasActiveSubscription() === true);
		this.subscriptionWillExpire$.set(false);
		this.subscriptionHasExpired$.set(false);
	}
}
