import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { slideHorizontallyAnimation } from '../../animations/slide-up-down/slide-horizontally.animation';
import { slideUpDownAnimation } from '../../animations/slide-up-down/slide-up-down.animation';

@Component({
    selector: 'app-animated-pdf-viewer',
    templateUrl: './animated-pdf-viewer.component.html',
    styleUrls: ['./animated-pdf-viewer.component.scss'],
    imports: [MatButtonModule, NgxExtendedPdfViewerModule],
    animations: [slideHorizontallyAnimation, slideUpDownAnimation]
})
export class AnimatedPdfViewerComponent {
	@Input() pdfUrl: string = '';
	@Input() displayState: 'open' | 'closed' = 'closed';
	@Output() onClose = new EventEmitter<void>();
	orientation: 'horizontal' | 'vertical' = 'horizontal';

	constructor(private breakpointObserver: BreakpointObserver) {
		this.breakpointObserver
			.observe([Breakpoints.HandsetPortrait, Breakpoints.HandsetLandscape, Breakpoints.TabletPortrait])
			.subscribe((result) => {
				if (result.matches) {
					this.orientation = 'vertical';
				} else {
					this.orientation = 'horizontal';
				}
			});
	}

	onCloseAction(): void {
		this.onClose.emit();
	}
}
