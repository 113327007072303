import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface GenericErrorDialogParams {
	title?: string;
	message?: string;
	okText?: string;
}

@Component({
    selector: 'app-generic-error-dialog',
    templateUrl: './generic-error-dialog.component.html',
    styleUrls: ['./generic-error-dialog.component.scss'],
    standalone: false
})
export class GenericErrorDialogComponent {
	public title: string = 'An Error Occurred';
	public message: string = 'Please contact support or your again later';
	public okText: string = 'OK';

	constructor(
		public dialogRef: MatDialogRef<GenericErrorDialogParams>,
		@Inject(MAT_DIALOG_DATA) public data: GenericErrorDialogParams
	) {
		if (data.title) {
			this.title = data.title;
		}
		if (data.message) {
			this.message = data.message;
		}
		if (data.okText) {
			this.okText = data.okText;
		}
	}
}
