import { Component, Inject } from '@angular/core';
import {
	MatDialog,
	MatDialogContent,
	MatDialogActions,
	MatDialogClose,
	MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UserStoreService } from '../../../../../services/stores/user-store.service';
import { SubscriptionService } from '../../../../../services/subscriptions/subscription.service';

export function openManageSubscriptionDialog(dialog: MatDialog, planFrequency: 'monthly' | 'yearly' = 'monthly') {
	const dialogConfig = {
		width: '30rem',
		height: '36rem',
		data: {
			planFrequency: planFrequency,
		},
	};
	return dialog.open(ManageSubscriptionDialogComponent, dialogConfig);
}

@Component({
    selector: 'app-manage-subscription-dialog',
    templateUrl: './manage-subscription-dialog.component.html',
    styleUrls: ['./manage-subscription-dialog.component.scss'],
    imports: [MatDialogContent, MatProgressSpinnerModule, MatDialogActions, MatButtonModule, MatDialogClose]
})
export class ManageSubscriptionDialogComponent {
	public manageSubscriptionLink: SafeResourceUrl | undefined;
	public isLoading = true;
	public hasError = false;
	constructor(
		private userStoreService: UserStoreService,
		private subscriptionService: SubscriptionService,
		@Inject(MAT_DIALOG_DATA)
		manageSubscriptionDialogData: {
			planFrequency: 'monthly' | 'yearly';
		},
		domSanitizer: DomSanitizer,
	) {
		this.subscriptionService.getHostedPaymentPage(manageSubscriptionDialogData.planFrequency).then((results) => {
			this.manageSubscriptionLink = domSanitizer.bypassSecurityTrustResourceUrl(results.url);
		});
	}

	public onLoaded(event: Event) {
		// The way angular injects the iframe causes the onload event to fire twice, once before the actual URL is bound
		if (event?.target instanceof HTMLIFrameElement) {
			if (event.target.src) {
				this.isLoading = false;
			}
		}
	}

	public onError() {
		this.isLoading = false;
		this.hasError = true;
	}

	public onClose() {
		// fetch the latest user data in the event
		// changes were made to the subscription
		this.userStoreService.reinitialize();
	}
}
